import { h } from 'preact';
import { Component } from 'react';
import ConversionForm from '#/ConversionForm';
import PropTypes from 'prop-types';
import { setRecaptchaLoadOnFormFocus } from '../utils/reCAPTCHA';

export default class NewVehicleConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variants: [],
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);
  }

  handleVariantChange(variant) {
    const activeVersion = variant.activeVersionObject();
    window.store.vehicleVersionDetails.setState({ activeVersion });
    this.props.handleVariantChange &&
      this.props.handleVariantChange(activeVersion);
  }

  handleCloseOverlay(e) {
    this.setState({ showOverlay: false });
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true }, () => {
      let status = 'error',
        data = null;

      return serviceConversion
        .convert(params)
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false,
          });
          status = 'success';
          data = params;
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true,
          });
          data = error;
          console.error(error);
        })
        .then(() => {
          document.dispatchEvent(
            new CustomEvent('new_conversion', {
              detail: {
                status: status,
                data: data,
              },
            }),
          );
          this.setState({
            showOverlay: true,
          });
        });
    });
  }

  componentDidMount() {
    if (this.props.asynchronouslyLoaded) {
      setRecaptchaLoadOnFormFocus();
    }
  }

  componentWillMount() {
    this.props.cloneId &&
      service.getNewModelDetails(this.props.cloneId).then(res => {
        this.setState({
          variants: res.entries,
        });
      });
  }

  render() {
    return (
      <ConversionForm
        formId="conversion-from-new-model"
        variants={this.state.variants}
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        showOverlay={this.state.showOverlay}
        handleSubmit={this.handleSubmit}
        handleCloseOverlay={this.handleCloseOverlay}
        handleVariantChange={this.handleVariantChange}
        titleForm={this.props.titleForm}
        mainPhrase={this.props.mainPhrase}
        shouldShowImage={this.props.shouldShowImage}
        shouldShowPrice={this.props.shouldShowPrice}
        shouldShowVariantSelect={this.props.shouldShowVariantSelect}
        defaultVariant={this.props.defaultVariant}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        brand={this.props.brand}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        showLastName={this.props.showLastName}
        zflow={this.props.zflow}
        cloneId={this.props.cloneId}
        model_year={this.props.model_year}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showLabels={this.props.showLabels}
        showVehicleExchange={this.props.showVehicleExchange}
        customFields={this.props.customFields}
        showFandiButton={this.props.showFandiButton}
        showLocationFields={this.props.showLocationFields}
        privacyPolicyStyle={this.props.privacyPolicyStyle}
        shouldShowVariantName={this.props.shouldShowVariantName}
        buttonLabel={this.props.buttonLabel}
        modelCategory={this.props.modelCategory}
      />
    );
  }
}

NewVehicleConversionFormApp.defaultProps = {
  handleVariantChange: function () {},
  shouldShowImage: true,
  shouldShowPrice: true,
  shouldShowVariantSelect: true,
  showLocationFields: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  shouldShowVariantName: false,
  // defaultVariant representa o valor de variante selecionado
  // Esse valor terá precedência sobre o valor selecionado pelo CustomSelect,
  // portanto, cuidado.
  defaultVariant: '',
  units: [],
  filterUnitsByLocation: false,
  brand: '-',
  showUnits: true,
  showCpf: false,
  showLastName: false,
  showFandiButton: false,
  zflow: false,
  showLabels: false,
  showVehicleExchange: true,
  customFields: [],
  privacyPolicyStyle: 'link',
  asynchronouslyLoaded: false,
};

NewVehicleConversionFormApp.propTypes = {
  linkPrivacyPolicy: PropTypes.string,
  buttonLabel: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  filterUnitsByLocation: PropTypes.bool,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  showLastName: PropTypes.bool,
  shouldShowVariantSelect: PropTypes.bool, // para usar com os layouts FCA | Chevrolet
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  shouldShowVariantName: PropTypes.bool,
  showFandiButton: PropTypes.bool,
  showLabels: PropTypes.bool,
  showVehicleExchange: PropTypes.bool,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  privacyPolicyStyle: PropTypes.oneOf(['check', 'link']),
  asynchronouslyLoaded: PropTypes.bool,
};
